import React from 'react';
import Map from '../embed-map/Map';
import PgeVideoPlayer from '../pge-video-player/PgeVideoPlayer';
import RichText from '../rich-text';
import useStyles from './MediaWrapper.styles';
import { MediaWrapperProps as Props } from './types.d';

function MediaWrapper({
  embedMediaHeader,
  // embedMediaAltText,
  embedMediaCaption,
  url,
  googleMapUrl,
  esri,
}: Props): ElementNode {
  const classes = useStyles();

  return (
    <figure className={classes.root}>
      {embedMediaHeader && (
        <div className={classes.header}>{embedMediaHeader}</div>
      )}
      {googleMapUrl && <Map src={googleMapUrl} />}
      {esri && <Map src={esri} />}
      {url && (
        <div className={classes.videoContainer}>
          <PgeVideoPlayer video={{ url }} />
        </div>
      )}
      <RichText wrap="figcaption">{embedMediaCaption}</RichText>
    </figure>
  );
}

export default MediaWrapper;
