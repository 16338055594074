import React, { FunctionComponent, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      '& > p': {
        margin: 0,
      },
    },
    contactCopy: {
      color: colors.noirBlur,
      fontStyle: 'italic',
    },
    contactName: {
      color: colors.noirBlur,
      fontWeight: 'bold',
    },
    info: {
      color: colors.sparkBlue,
      fontWeight: 'bold',
      '& > a': {
        textDecoration: 'none',
      },
    },
  }),
);

interface contactProps {
  contactName?: string;
  emailAddress?: React.ReactNode;
  phoneNumber?: React.ReactNode;
  contactCopy?: React.ReactNode;
}

export default (props: contactProps) => {
  const classes = useStyles();
  const { contactName, phoneNumber, emailAddress, contactCopy } = props;

  return (
    <Container className={classes.root}>
      {contactCopy && <div className={classes.contactCopy}>{contactCopy}</div>}
      {contactName && <p className={classes.contactName}>{contactName}</p>}
      {phoneNumber && <p className={classes.info}>{phoneNumber}</p>}
      {emailAddress && <p className={classes.info}>{emailAddress}</p>}
    </Container>
  );
};
