import React from 'react';
import { Button, Typography } from '@material-ui/core';
import scrollTo from 'gatsby-plugin-smoothscroll';
import useStyles from './JumpLink.styles';
import RichText, { richTextStyles } from '../../rich-text';

interface JumpLinkProps {
  jumpLinkHeading?: string;
  outlineEntries?: any;
  jumpLinkIntroCopy?: any;
}
function JumpLink({
  outlineEntries,
  jumpLinkHeading,
  jumpLinkIntroCopy,
}: JumpLinkProps): ElementNode {
  const classes = useStyles();
  const richText = richTextStyles();
  return (
    <article className={classes.root}>
      <div className={classes.header}>
        {jumpLinkHeading && (
          <Typography variant="h1">{jumpLinkHeading}</Typography>
        )}

        <RichText className={richText.styles}>{jumpLinkIntroCopy}</RichText>
      </div>
      <div className={classes.content}>
        <ul>
          {outlineEntries?.map((entry: any, index: number) => {
            const anchorId = `#_${entry?.contentful_id}`;
            return (
              <li key={index}>
                <Button
                  data-testid={index}
                  className={classes.text}
                  onClick={() => scrollTo(anchorId)}
                >
                  {entry?.anchorLabel}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
}

export default JumpLink;
