import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from '../../themes/main-colors';
import RichText, { richTextStyles } from '../rich-text';

interface IProps {
  accordionItems: any;
  accordionHeader?: string;
  accordionIntroCopy?: any;
  colorBackground?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      width: '100%',
      padding: theme.spacing(5, 7.5),
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: theme.spacing(0.675),
      backgroundColor: props?.colorBackground
        ? props?.colorBackground
        : 'transparent',
      '& > div.MuiPaper-root': {
        margin: '16px 0',
        boxShadow: '0px 3px 6px #00002930',
        borderRadius: theme.typography.pxToRem(5),
        '& > div > div:nth-child(1)': {
          display: 'block',
        },
        '& > div.icon > div:nth-child(1)': {
          display: 'flex',
          '& > div > div > img': {
            marginRight: theme.typography.pxToRem(10),
            width: theme.typography.pxToRem(33.32),
            height: theme.typography.pxToRem(28),
          },
        },
      },
      '& > div:before': {
        height: '0',
      },
      '& .MuiAccordionSummary-root': {
        minHeight: theme.spacing(8.75),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5),
      },
    }),
    heading: {
      fontSize: theme.typography.pxToRem(24),
      flexShrink: 0,
      lineHeight: theme.typography.pxToRem(28),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(20),
      marginTop: theme.spacing(1.25),
    },
    icon: {
      display: 'flex',
      flex: 'auto',
      maxWidth: '40px',
      justifyContent: 'center',
      alignItems: 'start',
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        maxWidth: '60px',
      },

      '& img': {
        width: '100%',
      },
    },
    content: {
      flex: 1,
      padding: theme.spacing(0, 0.5, 0, 2),
      overflow: 'hidden',

      '& h2': {
        fontSize: '1.25rem',
        fontWeight: 500,

        [theme.breakpoints.up('md')]: {
          fontSize: '1.5rem',
        },
      },
    },
    header: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(40),
      fontWeight: 600,
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    accordionDetails: {
      '& img': {
        width: 'inherit',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .RichText': {
        width: '100%',
      },
    },
  }),
);

const PGEAccordion = (props: IProps): React.ReactElement => {
  const classes = useStyles(props);
  const richText = richTextStyles();
  const { accordionItems, accordionIntroCopy, accordionHeader } = props;
  const initialItems: any = [...accordionItems];
  const [expands, setExpands] = React.useState<Array<boolean>>([
    ...initialItems?.fill(false),
  ]);
  // Handle Expand and Collapse of Accordion
  const handleChange =
    (index: number) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean): void => {
      const data: Array<boolean> = [...expands];
      data[index] = isExpanded;

      setExpands(data);
    };

  const accordion = accordionItems?.map(
    (panel: any, index: number): React.ReactElement => {
      return (
        <Accordion
          data-testid={index}
          expanded={expands[index]}
          onChange={handleChange(index)}
          key={index}
        >
          <AccordionSummary
            data-testid={`panel${index}bh-header`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            className={panel.tabTitleIcon ? 'icon' : ''}
          >
            {panel.tabTitleIcon && (
              <picture className={classes.icon}>
                {panel?.tabTitleIcon?.file && (
                  <img
                    src={panel?.tabTitleIcon?.file?.url}
                    alt={panel?.tabTitle}
                  />
                )}
                {panel?.tabTitleIcon?.$$typeof && <>{panel?.tabTitleIcon}</>}
              </picture>
            )}
            <div className={classes.content}>
              {(panel?.tabTeaser || panel.tabTitle) && (
                <>
                  <Typography className={classes.heading} variant="h2">
                    {panel.tabTitle}
                  </Typography>

                  {panel?.tabTeaser && (
                    <Typography className={classes.secondaryHeading}>
                      {panel?.tabTeaser}
                    </Typography>
                  )}
                </>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {panel?.tabBody?.raw ? (
              <RichText className={`RichText ${richText.styles}`}>
                {panel?.tabBody}
              </RichText>
            ) : (
              panel?.tabBody
            )}
          </AccordionDetails>
        </Accordion>
      );
    },
  );
  return (
    <div className={classes.root}>
      {accordionHeader && (
        <Typography variant="h1" className={classes.header}>
          {accordionHeader}
        </Typography>
      )}
      {accordionIntroCopy && (
        <RichText className={`RichText ${richText.styles}`}>
          {accordionIntroCopy}
        </RichText>
      )}
      {accordion}
    </div>
  );
};

export default PGEAccordion;
