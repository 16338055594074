import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { Link } from 'gatsby';
import { usePathContext } from '../../providers/PathProvider';
import { FileType, LinkType } from '../../constants';

interface BaseProps extends HTMLAttributes<HTMLAnchorElement> {
  slug?: string;
  linkText?: ReactNode;
}

type Props = Exclude<BaseProps, 'href' | 'target' | 'rel'>;

const SlugLink: FC<Props> = ({ slug, linkText = '', ...attrs }) => {
  if (!slug) {
    return <>{linkText}</>;
  }

  const externalLink: boolean = LinkType.HTTP.test(slug);
  const documentLink: boolean = FileType.DOC.test(slug);
  const imageLink: boolean = FileType.IMG.test(slug);
  const isBlank: boolean = externalLink || documentLink || imageLink;

  const { resolve } = usePathContext();

  const reactNode = linkText || slug;

  return isBlank ? (
    <a href={slug} target="_blank" rel="noreferrer noopener" {...attrs}>
      {reactNode}
    </a>
  ) : (
    <Link to={resolve(slug) as string} {...attrs} data-testid="slug-link">
      {reactNode}
    </Link>
  );
};

export default SlugLink;
