import React, { FC, TouchEvent } from 'react';
import { SlideProps as Props } from './types.d';
import useStyles from './GallerySlider.styles';
import { useSliderContext } from './GallerySlider';
import { Animate } from '../../../constants';

/**
 * Helper Funcs
 */

const createHelpers = (dispatch: any) => {
  let initPos = 0;
  let distance = 0;

  return {
    touchResponse(event: TouchEvent<HTMLElement>): void {
      event.preventDefault();

      const { currentTarget } = event;
      const threshold = (currentTarget.offsetWidth || 100) / 5;

      if (event.type === 'touchstart') {
        initPos = event.touches[0].clientX;
      }

      if (['touchend', 'touchcancel'].includes(event.type)) {
        currentTarget.style.transition = 'transform 0.6s, opacity 0.4s';

        // PREV
        if (event.type === 'touchend' && distance < -threshold) {
          dispatch({ type: Animate.PREV });

          // NEXT
        } else if (event.type === 'touchend' && distance > threshold) {
          dispatch({ type: Animate.NEXT });

          // CANCEL
        } else {
          currentTarget.style.transform = 'translateX(0)';
        }
      }
    },
    touchTrace(event: TouchEvent<HTMLElement>): void {
      event.preventDefault();

      const { currentTarget } = event;

      distance = initPos - event.touches[0].clientX;
      currentTarget.style.transition = '0s';
      currentTarget.style.transform = `translateX(${-distance}px)`;
    },
  };
};

const Slide: FC<Props> = ({ image, imageAltText }) => {
  const { url } = image?.file || {};
  const { dispatch } = useSliderContext();
  const { touchResponse, touchTrace } = createHelpers(dispatch);
  const classes = useStyles();

  return (
    <div
      className={`GallerySlide ${classes.slide}`}
      onTouchStart={touchResponse}
      onTouchEnd={touchResponse}
      onTouchCancel={touchResponse}
      onTouchMove={touchTrace}
    >
      {url && (
        <>
          <picture className="blur-bkg">
            <img src={`${url}?w=50`} alt="" />
          </picture>
          <picture>
            <img src={url} alt={imageAltText?.imageAltText || ''} />
          </picture>
        </>
      )}
    </div>
  );
};

export default Slide;
