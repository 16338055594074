import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Size, Shadow } from '../../../constants';
import { grid, cell } from './lib/grid';

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => {
  // Colors
  const { white } = palette.common;
  const blue = palette.secondary.main;
  const offwhite = palette.grey[100];

  return createStyles({
    root: {
      contain: 'content',
      paddingBottom: spacing(2),
      margin: spacing(6, 0),
      overflow: 'hidden',
      boxShadow: Shadow.ONE,

      [breakpoints.up('sm')]: {
        padding: spacing(0, 2.5, 4.25),
      },

      '&.bkg-None': {
        background: white,
      },

      '&.bkg-Blue': {
        background: blue,
      },

      '&.bkg-Grey': {
        background: offwhite,
      },

      '& h1, & > p': {
        boxSizing: 'content-box',
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: spacing(0, 2),

        [breakpoints.up('sm')]: {
          padding: spacing(0, 1.25),
        },
      },

      '& h1': {
        marginTop: spacing(2.5),

        [breakpoints.up('sm')]: {
          marginTop: spacing(4),
        },
      },

      '& > p': {
        marginTop: spacing(3),

        [breakpoints.up('sm')]: {
          marginTop: spacing(4.5),
        },
      },

      '& h1 + p': {
        marginTop: spacing(2),
      },

      '& .graphical span': {
        width: 1,
        height: 1,
        position: 'absolute',
        overflow: 'hidden',
      },
    },

    container: {
      contain: 'layout',
      maxWidth: 820,
      margin: spacing(2, 'auto', 0),

      [breakpoints.up('sm')]: {
        padding: 10,
        marginTop: spacing(2.5),
      },
    },

    // Track
    track: {
      contain: 'size',
      padding: `168% 0 0`,
      position: 'relative',
      transformStyle: 'preserve-3d',

      [breakpoints.up('sm')]: {
        paddingTop: '75%',
      },
    },

    // Slide
    slide: {
      contain: 'strict',
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      opacity: 0,
      boxShadow: Shadow.ONE,
      transform: 'translateX(-100%)',
      backfaceVisibility: 'hidden',

      [breakpoints.up('sm')]: {
        borderRadius: 5,
      },

      '& picture': {
        pointerEvents: 'none',
      },

      '& img': {
        height: '100%',
      },

      '& picture:first-of-type img': {
        width: '100%',
        opacity: 0.75,
        objectFit: 'cover',
        filter: 'blur(12px)',
        transform: 'scale(1.1)',
      },

      '& picture:last-of-type img': {
        width: 'auto',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },

    // Caption
    wrap: {
      ...grid('auto auto', '1fr auto'),
      boxSizing: 'content-box',
      contain: 'content',
      maxWidth: 800,
      padding: spacing(0, 2),
      position: 'relative',
      margin: spacing(2, 'auto', 0),
      rowGap: `${spacing(2)}px`,

      [breakpoints.up('sm')]: {
        padding: spacing(0, 1.25),
        marginTop: spacing(2.5),
        rowGap: 0,
      },
    },

    caption: {
      ...cell('Row', 2, 3),
      ...cell('Column', 1, 3),
      width: '100%',
      opacity: 0,

      [breakpoints.up('sm')]: {
        ...cell('Row', 1, 3),
        ...cell('Column', 1, 2),
      },

      '& h2': {
        fontWeight: 700,
      },

      '& h2 + p': {
        marginTop: '.5em',
      },
    },

    // Position
    position: {
      ...cell('Row', 1, 2),
      ...cell('Column', 1, 3),
      margin: 0,
      fontSize: '1.4rem',
      fontWeight: 700,
      wordSpacing: '.4ch',
      letterSpacing: '.06ch',

      [breakpoints.up('sm')]: {
        ...cell('Column', 2, 3),
      },
    },

    // Nav Buttons
    arrow: {
      boxSizing: 'content-box',
      width: Size.TOUCH,
      height: Size.TOUCH,
      padding: 0,
      position: 'absolute',
      top: '50%',
      zIndex: 3,
      outline: 0,
      color: offwhite,
      border: 0,
      background: 'rgba(7,26,43,.4)',
      transform: 'translateY(-50%) scale(1)',
      cursor: 'pointer',

      '&:first-of-type': {
        left: 0,
        borderRadius: '0 5px 5px 0',
        transformOrigin: '0 50%',
      },

      '&:last-of-type': {
        right: 0,
        borderRadius: '5px 0 0 5px',
        transformOrigin: '100% 50%',
      },

      '& svg': {
        width: '100%',
        height: '100%',
        filter: `drop-shadow(0 3px 6px rgba(0,0,0,.4))`,
      },

      '&:focus': {
        background: 'rgba(7,26,43,1)',
      },

      '&:active': {
        transform: 'translateY(-50%) scale(.94)',
      },

      [breakpoints.up('sm')]: {
        width: Size.TOUCH + 10,
        padding: spacing(2, 0),

        '&:hover': {
          background: 'rgba(7,26,43,1)',
        },
      },
    },
  });
});

export default useStyles;
