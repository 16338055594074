/**
 * TODO:
 * Adopt as the only PgeButton. This is a part of a larger effort to combine
 * all buttons and links into a single component.
 */

import React, {
  useState,
  forwardRef,
  ForwardRefExoticComponent as FREC,
} from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import SvgMap from '../svg-map';
import Overlay from '../pge-overlay/_Overlay';
import { usePathContext } from '../../providers/PathProvider';
import useLink from '../../hooks/useLink';
import { isOverlay } from '../../util/type-checking';
import { FileType, LinkType, Icon } from '../../constants';
import { PgeButtonProps as Props, ThemeMap, BaseClasses } from './types.d';
import useStyles from './PgeButton.styles';

const PgeButton: FREC<Props> = forwardRef(
  (
    { link, children, startIcon, endIcon, className, theme, display, ...props },
    ref,
  ) => {
    // Default props
    const _class = className || '';
    const _theme = theme || 'Primary';
    const _display = display || 'Block';

    // Vars
    const href = useLink(link);
    const overlay = isOverlay(link) ? link : null;
    const isButton = link === undefined || !!overlay;
    const isAnchor = !isButton && !!href;

    if ((isButton || overlay) && !children) {
      return null;
    }

    if (!isButton && !isAnchor) {
      return <>{children}</>;
    }

    const externalLink = href.match(LinkType.HTTP);
    const documentLink = href.match(FileType.DOC);
    const imageLink = href.match(FileType.IMG);

    let inlineIcon: Icon['Type'];
    if (documentLink?.[1] === 'xls' || documentLink?.[1] === 'xlsm') {
      inlineIcon = 'xlsx';
    } else {
      inlineIcon = (documentLink?.[1] as Icon['Type']) || externalLink?.[0];
    }

    const isBlank = !!externalLink || !!documentLink || !!imageLink;
    const iconEnd = inlineIcon ?? endIcon;

    const { resolve } = usePathContext();
    const classes = useStyles();

    const sharedClasses: BaseClasses = {
      root: classes.root,
      contained: classes.contained,
      disabled: classes.disabled,
      outlined: classes.outlined,
      endIcon: classes.endIcon,
      startIcon: classes.startIcon,
    };

    const baseProps: ButtonProps = {
      variant: 'contained',
      color: 'primary',
      classes: sharedClasses,
    };

    const themeMap: ThemeMap = {
      'Back/Cancel': {
        ...baseProps,
        classes: { ...sharedClasses, containedPrimary: classes.back },
      },
      Primary: {
        ...baseProps,
        classes: { ...sharedClasses, containedPrimary: classes.primary },
      },
      Emphasis: {
        ...baseProps,
        classes: { ...sharedClasses, containedPrimary: classes.emphasis },
      },
      Outline: {
        ...baseProps,
        variant: 'outlined',
        classes: { ...sharedClasses, outlinedPrimary: classes.outline },
      },
      Secondary: {
        ...baseProps,
        variant: 'outlined',
        classes: { ...sharedClasses, outlinedPrimary: classes.secondary },
      },
      Text: {
        ...baseProps,
        variant: 'text',
        classes: { ...sharedClasses, textPrimary: classes.text },
      },
      Pill: {
        ...baseProps,
        variant: 'outlined',
        classes: { ...sharedClasses, outlinedPrimary: classes.pill },
      },
    };

    const [open, setOpen] = useState(false);

    return (
      <>
        <Button
          className={`PgeButton btn-${_theme} btn-${_display} ${_class}`}
          component={isAnchor ? 'a' : 'button'}
          href={isAnchor ? resolve(href) : undefined}
          target={isBlank ? '_blank' : undefined}
          rel={isBlank ? 'noopener noreferrer' : undefined}
          startIcon={startIcon ? <SvgMap use={startIcon} /> : undefined}
          endIcon={iconEnd ? <SvgMap use={iconEnd} /> : undefined}
          onClick={overlay ? () => setOpen(true) : undefined}
          ref={ref}
          {...props}
          {...themeMap[_theme]}
        >
          {children || href}
        </Button>
        {overlay && (
          <Overlay
            content={overlay.content}
            open={open}
            setOpen={setOpen}
            entryName={overlay.entryName}
            statusAlert={overlay.statusAlert}
          />
        )}
      </>
    );
  },
);

export default PgeButton;
