type Move = (el?: HTMLElement) => void;

export const clear: Move = el => {
  if (el) {
    el.style.opacity = '0';
    el.style.transition = '0s';
    el.style.transform = 'translateX(-100%)';
  }
};

export const setRight: Move = el => {
  if (el) {
    el.style.transform = 'translateX(100%)';
  }
};

export const left: Move = el => {
  if (el) {
    el.style.opacity = '0';
    el.style.transition = 'transform 0.6s, opacity 0.6s';
    el.style.transform = `translateX(-100%)`;
  }
};

export const center: Move = el => {
  if (el) {
    el.style.opacity = '1';
    el.style.transition = 'transform 0.6s, opacity 0.6s';
    el.style.transform = `translateX(0)`;
  }
};

export const right: Move = el => {
  if (el) {
    el.style.opacity = '0';
    el.style.transition = 'transform 0.6s, opacity 0.6s';
    el.style.transform = `translateX(100%)`;
  }
};
