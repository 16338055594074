import { ComponentPropsWithoutRef } from 'react';
import { Entry, Field } from '../../../contentful.d';

export const ItemOmits = ['aria-label', 'style', 'tabTeaser'] as const;

export const ListOmits = ['aria-label', 'style'] as const;

interface ItemBase
  extends GatsbyTypes.ListItemFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly length: number;
  readonly pos: number;
}

interface ListBase
  extends GatsbyTypes.StaticListFragment,
    ComponentPropsWithoutRef<'article'> {
  readonly colorBackground?: Field['Background'];
  readonly imagePosition?: Field['Position'];
}

export type ListItemProps = Partial<Omit<ItemBase, typeof ItemOmits[number]>>;

export type StaticListProps = Partial<Omit<ListBase, typeof ListOmits[number]>>;
