import React from 'react';
import { Typography } from '@material-ui/core';
import RichText, { richTextStyles } from '../../rich-text';
import ListItem from './ListItem';
import useAttrs from '../../../hooks/useAttrs';
import { StaticListProps as Props, ListOmits } from './types.d';
import useStyles from './StaticList.styles';

function StaticList({
  staticListHeader,
  staticListIntroCopy,
  staticListImage,
  addItems,
  colorBackground,
  imagePosition,
  ...props
}: Props): ElementNode {
  // Default props
  const _color = colorBackground || 'None';

  // Vars
  const { className = '', ...attrs } = useAttrs<Props>(props, ListOmits);
  const classes = useStyles({imagePosition});
  const richText = richTextStyles();

  return (
    <article
      className={`StaticList bkg-${_color} ${classes.root_StaticList} ${className}`}
      aria-label={staticListHeader || undefined}
      {...attrs}
    >
      {staticListImage?.file?.url && (
        <picture className={classes.hero}>
          <img src={staticListImage.file.url} alt="" />
        </picture>
      )}
      <div className={classes.list}>
        {staticListHeader && (
          <header>
            <Typography variant="h1">{staticListHeader}</Typography>
            <RichText className={`RichText ${richText.styles}`}>
              {staticListIntroCopy}
            </RichText>
          </header>
        )}
        {addItems?.map((item, index) => (
          <ListItem
            pos={index + 1}
            length={addItems.length}
            key={`${item?.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </article>
  );
}

export default StaticList;
