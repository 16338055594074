import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({ palette, typography, breakpoints, spacing }: Theme) => {
    // Colors
    const text = palette.text.primary;

    return createStyles({
      root: {
        margin: 0,

        '& figcaption': {
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        },
      },
      header: {
        color: text,
        fontSize: spacing(4.5),
        lineHeight: typography.pxToRem(40),
        fontWeight: 100,
        [breakpoints.down('sm')]: {
          fontSize: spacing(3),
          lineHeight: typography.pxToRem(28),
        },
      },
      videoContainer: {
        padding: spacing(2.5, 0),
        display: 'flex',
        justifyContent: 'center',
        '& > iframe': {
          maxHeight: 576,
          width: '100%',
          maxWidth: 854,
          borderRadius: 5,
          height: 480,
          [breakpoints.down('sm')]: {
            height: 275,
          },
        },
      },
    });
  },
);

export default useStyles;
