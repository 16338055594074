import React, { FunctionComponent } from 'react';
import Container from '@material-ui/core/Container';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Field } from '../../contentful.d';
// tslint:disable-next-line
import './styles/hero.css';

import TEST_IDS from '../../constants/test_ids';

interface LandingHeroImageProps {
  heading: string;
  image?: string;
  headingLineTwo?: string;
  subheading?: string;
  copy?: string;
  ctaText?: string;
  ctaLink?: string;
  button?: IPGEButtonProps | null;
  readonly imagePosition: Field['Position'];
}

export const LandingHeroImage: FunctionComponent<LandingHeroImageProps> = ({
  image,
  heading,
  headingLineTwo,
  subheading,
  copy,
  ctaText,
  ctaLink,
  button,
  imagePosition,
}) => {
  const backgroundPictureUrl = image ? `url(${image})` : 'none';
  const heroImageCSS =
    // HCL asked to keep this code to engage it post MVP.
    'linear-gradient(90deg, #071A2BE6, #122B417F, #20415B00) ,' +
    backgroundPictureUrl;

  return (
    <>
      <div
        className={`position-${imagePosition} hero-container`}
        data-testid={TEST_IDS.LANDING_HERO_HEADER}
        style={{
          backgroundImage: heroImageCSS,
          // HCL asked to keep this code to engage it post MVP.
          // the position has to entries, the first for the gradient 'picture', the second for the image itself.
          // backgroundPosition: '0px 0px, 80% 50%',
        }}
      >
        <Container>
          {heading && <h1>{heading}</h1>}
          {headingLineTwo && <h1 className="heading-two">{headingLineTwo}</h1>}
          {subheading && <h3>{subheading}</h3>}
          {copy && <p>{copy}</p>}
          {button && <PgeButton {...button} />}
          {ctaText && <a href={ctaLink}>{ctaText}</a>}
        </Container>
      </div>
    </>
  );
};
