import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIsMobile } from '../../util/style-utils';

export interface JotProps {
  src?: string;
}

const Jot: FunctionComponent<JotProps> = props => {
  const { src } = props;
  const [height, setHeight] = useState(465);
  const isMobile = useIsMobile();

  const getFrameHeight = (e: any) => {
    if (!e.data.split) {
      return;
    }
    const args = e.data.split(':');

    switch (args[0]) {
      case 'setHeight': {
        const extraHeight = parseInt(args[1]) + (isMobile ? 220 : 150);
        setHeight(extraHeight);
        break;
      }
      case 'reloadPage':
        window.location.reload();
        break;
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', getFrameHeight, false);
    }
    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', getFrameHeight, false);
      }
    };
  }, []);

  return (
    <iframe
      src={src}
      frameBorder="0"
      style={{ width: '100%', height: `${height}px`, border: 'none' }}
      scrolling="yes"
    />
  );
};

export default Jot;
