import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PgeButton from '../pge-button/_PgeButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      marginBottom: 0,
      '& > li': {
        padding: theme.spacing(0.65, 0),
        '& > .btn-Block': {
          fontSize: theme.typography.pxToRem(20),
          minHeight: 'auto',
        },
      },
    },
  }),
);

interface IProps {
  list: any;
  className?: string;
}

const RelatedList = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { list } = props;
  const listItems = list?.map((item: any, index: number): JSX.Element => {
    return (
      <li key={index} data-testid={index}>
        <PgeButton theme="Text" link={item}>
          {item?.title ?? item?.entryName}
        </PgeButton>
      </li>
    );
  });

  return <ul className={classes.root}>{listItems}</ul>;
};

export default RelatedList;
