import React, { FC, useEffect, useRef, MutableRefObject } from 'react';
import { Typography } from '@material-ui/core';
import Slide from './Slide';
import Caption from './Caption';
import RichText from '../../rich-text';
import NavBtn from './NavBtn';
import { useSliderContext } from './GallerySlider';
import * as Move from './lib/move';
import { Animate } from '../../../constants';
import { GalleryProps as Props, Omits } from './types.d';
import useStyles from './GallerySlider.styles';
import useAttrs from '../../../hooks/useAttrs';

/**
 * Helper Funcs
 */

const createHelpers = (auto: MutableRefObject<boolean>) => ({
  cancelAuto() {
    auto.current = false;

    return auto;
  },
});

const Container: FC<Props> = ({
  children,
  galleryHeader,
  galleryIntroCopy,
  autoPlay,
  slideTiming,
  colorBackground,
  ...props
}) => {
  if (!children) {
    return null;
  }

  // Default Props
  const _color = colorBackground ?? 'None';

  // Vars
  const { dispatch, context } = useSliderContext();
  const $track = useRef<HTMLDivElement>(null);
  const $slides = useRef<Array<HTMLDivElement>>([]);
  const $auto = useRef(!!autoPlay);
  const $time = useRef((slideTiming || 6) * 1000);
  const classes = useStyles({});
  const { forward } = context;
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const { cancelAuto } = createHelpers($auto);

  /**
   * onMount
   */

  useEffect(() => {
    const track = $track.current;

    if (track) {
      const list = track.querySelectorAll<HTMLDivElement>('.GallerySlide');

      $slides.current = [...list];
    }
  }, []);

  /**
   * onStateChanges
   */

  useEffect(() => {
    const list = $slides.current;
    const currentSlide = list[context.position];
    const prevSlide = list[context.prev];
    const nextSlide = list[context.next];
    let startTime = 0;

    // Animate frames
    const slideTransitions = (timeStamp: DOMHighResTimeStamp): void => {
      if (!startTime) {
        startTime = timeStamp;
      }

      const totalTime = timeStamp - startTime;

      Move.clear(currentSlide);

      if (forward) {
        Move.setRight(currentSlide);
      }

      if (totalTime >= 16) {
        startTime = 0;

        Move.center(currentSlide);

        if (forward) {
          Move.left(prevSlide);
        } else {
          Move.right(nextSlide);
        }
      } else {
        window.requestAnimationFrame(slideTransitions);
      }
    };

    if ($auto.current) {
      const playNext = window.setTimeout(() => {
        if ($auto.current) {
          dispatch({ type: Animate.NEXT });
        }
        window.clearTimeout(playNext);
      }, $time.current);
    }

    window.requestAnimationFrame(slideTransitions);
  }, [context]);

  return (
    <section
      className={`GallerySlider ${classes.root} ${className} bkg-${_color}`}
      aria-label={galleryHeader || undefined}
      {...attrs}
    >
      {galleryHeader && <Typography variant="h1">{galleryHeader}</Typography>}
      <RichText>{galleryIntroCopy}</RichText>
      <div
        className={classes.container}
        onMouseDown={cancelAuto}
        onTouchStart={cancelAuto}
      >
        <div className={classes.track} ref={$track}>
          {children.map((item, index) => (
            <Slide
              num={index}
              key={`slide-${item.contentful_id}${index}`}
              {...item}
            />
          ))}
          <NavBtn use="Prev">Previous</NavBtn>
          <NavBtn use="Next">Next</NavBtn>
        </div>
      </div>
      <div
        className={classes.wrap}
        onMouseDown={cancelAuto}
        onTouchStart={cancelAuto}
      >
        <p className={classes.position}>
          {context.position + 1} / {context.total}
        </p>
        {children.map((item, index) => (
          <Caption
            num={index}
            key={`caption-${item.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </section>
  );
};

export default Container;
