import React from 'react';
import { formatYoutubeUrl } from './pge-video.utils';

interface IVideoObj {
  url?: string;
  width?: number;
  height?: number;
}

interface IVideoProps {
  video: IVideoObj;
}

export default (props: IVideoProps) => {
  const { url } = props.video;

  if (!url) {
    return null;
  }

  const videoUrl = React.useMemo(() => formatYoutubeUrl(url), [url]);

  return <iframe src={videoUrl} frameBorder="0" allowFullScreen={true} />;
};
