import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorName } from '../../../constants';
import { CtaCardProps } from './types.d';

interface Props extends CtaCardProps {
  readonly imageHeight?: number;
  readonly imageRatio?: number;
}

const useStyles = makeStyles((theme: Theme) => {
  const { paper } = theme.palette.background;

  return createStyles({
    // CTA Card
    root: {
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      paddingTop: theme.spacing(4),
      marginTop: theme.spacing(2),
      overflow: 'hidden',
      flexDirection: 'column',
      flexWrap: 'wrap',
      borderRadius: '5px',

      '&.bkg-DarkGrey': {
        backgroundColor: ColorName['DarkGrey'],
      },

      '&.bkg-DarkBlue': {
        backgroundColor: ColorName['DarkBlue'],
      },

      '&.bkg-LightBlue': {
        backgroundColor: ColorName['LightBlue'],
      },

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },

      '& .RichText': {
        width: '100%',
        padding: theme.spacing(2, 4, 4),
        order: 3,
        color: paper,

        '& a, & button': {
          color: 'inherit',
          textDecoration: 'underline',
        },

        '& hr': {
          backgroundColor: paper,
        },
      },
    },

    heading: {
      width: '100%',
      padding: theme.spacing(0, 4),
      color: paper,

      [theme.breakpoints.up('sm')]: {
        flex: 1,
      },
    },

    cta: {
      margin: theme.spacing(2, 'auto', 0),
      order: 1,

      [theme.breakpoints.up('sm')]: {
        alignSelf: 'start',
        margin: theme.spacing(0, 3, 0, 'auto'),
      },
    },

    expand: ({ imageRatio = 300 }: Props) => ({
      '&.isExpanded': {
        paddingTop: `${imageRatio}%`,

        '&::after': {
          opacity: 0,
          transition: '.2s',
        },

        [theme.breakpoints.up('sm')]: {
          paddingTop: 0,
        },
      },
    }),

    image: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      paddingTop: 400,
      position: 'relative',
      marginTop: theme.spacing(2.5),
      order: 2,
      overflow: 'hidden',
      justifyContent: 'center',
      backgroundColor: paper,
      fontSize: 0,
      transform: 'translateZ(0)',
      transition: 'padding .5s',

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(4),
        paddingTop: 0,
      },

      '&::after': {
        display: 'block',
        width: '100%',
        height: '40%',
        position: 'absolute',
        bottom: 0,
        content: '""',
        zIndex: 1,
        background: 'linear-gradient(transparent, #fff) no-repeat',

        [theme.breakpoints.up('sm')]: {
          content: 'none',
        },
      },

      '& picture': {
        display: 'block',
        width: '100%',
      },

      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,

        [theme.breakpoints.up('sm')]: {
          position: 'relative',
        },
      },
    },

    showMore: {
      position: 'absolute',
      bottom: theme.spacing(2),
      zIndex: 2,

      '.isExpanded &': {
        opacity: 0,
        transition: 'opacity .2s',
      },

      [theme.breakpoints.up('sm')]: {
        'button&': { display: 'none' },
      },
    },
  });
});

export default useStyles;
