import React from 'react';
import { Typography } from '@material-ui/core';
import RichText, { richTextStyles } from '../../rich-text';
import PgeButton from '../../pge-button/_PgeButton';
import useAttrs from '../../../hooks/useAttrs';
import { ListItemProps as Props, ItemOmits } from './types.d';
import useStyles from './StaticList.styles';

function ListItem({
  tabTitle,
  titleLink,
  tabTitleIcon,
  tabBody,
  length,
  pos,
  ...props
}: Props): ElementNode {
  const img = tabTitleIcon?.file?.url;
  const withImage = img ? 'withImage' : '';
  const richText = richTextStyles();
  const { className = '', ...attrs } = useAttrs<Props>(props, ItemOmits);
  const classes = useStyles();

  return (
    <section
      className={`ListItem ${classes.root_ListItem} ${withImage} ${className}`}
      aria-label={`Part ${pos} of ${length}`}
      {...attrs}
    >
      {img && (
        <picture className={classes.icon}>
          <img src={img} alt="" />
        </picture>
      )}
      <div className={classes.content}>
        {tabTitle && (
          <Typography variant="h2">
            <PgeButton link={titleLink} theme="Text" display="Inline">
              {tabTitle}
            </PgeButton>
          </Typography>
        )}
        <RichText className={`RichText ${richText.styles}`}>{tabBody}</RichText>
      </div>
    </section>
  );
}

export default ListItem;
