import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import RichText, { richTextStyles } from '../components/rich-text';
import PgeContact from '../components/pge-contact';
import SplitHeader from '../components/split-header';
import PullQuote from '../components/pull-quote';
import CTA from '../components/call-to-action';
import PGEAccordion from '../components/accordion';
import Tabset from '../components/tabset';
import Jot from '../components/jot/';
import MessagingBlock from '../components/messaging-block';
import MediaWrapper from '../components/media-wrapper';
import Related from '../components/related';
import Overlay from '../components/pge-overlay';
import Callout from '../components/callout';
import Column from '../components/column';
import colors from '../themes/main-colors';
import PromoGroup from '../components/promo-group';
/*import PromoItem from '../components/promo-item';
import TableWrapper from '../components/contentful-table-wrapper';
import PgeButton from '../components/pge-button/PGEButtons';*/
import AlertNotifications from '../components/alert-notifications';
import { LandingHeroImage } from '../components/contentful-blocks/LandingHeroImage';
import { usePathContext } from '../providers/PathProvider';
import StaticList, { StaticListProps } from '../components/static-list';
import CTACard, { CtaCardProps } from '../components/cta-card';
/*import CardLayout, { CardLayoutProps } from '../components/card-layout';*/
import GallerySlider from '../components/gallery-slider';
import WrapperImage from '../components/wrapper-image';
import JumpLink from '../components/jump-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.25, 0),
      },
    },
    smallSpacing: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.8, 0),
      },
    },
    iconSize: {
      width: '14px !important',
      [theme.breakpoints.down('sm')]: {
        width: '14px !important',
      },
    },
  }),
);

export const releaseDateFormat = (releaseDate: any) => {
  const month = moment(releaseDate).format('MMMM');
  let monthFormat;

  switch (month) {
    case 'September': {
      monthFormat = 'Sept.';
      break;
    }
    default: {
      monthFormat = month?.length > 4 ? `${month?.substr(0, 3)}.` : month;
    }
  }
  return `${monthFormat} ${moment(releaseDate).format('DD, YYYY')}`;
};

export const OverlayComponent = (props: any) => {
  const { node, children, overlay } = props;
  const { content, entryName, statusAlert } = node?.data?.target || overlay;

  const [open, setOpen] = useState(false);
  const setOverlay = (status: any): any => {
    setOpen(status);
  };
  const statusComponent: any = statusAlert
    ? getStatusAlertComponent(statusAlert)
    : null;
  const contentBody = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {content}
    </RichText>
  );
  return (
    <>
      {/* This is an invalid anchor, needs to be a button */}
      <a style={{ cursor: 'pointer' }} onClick={(e) => setOpen(true)}>
        {children ? children : entryName}
      </a>
      <Overlay
        onOverlay={(status: any) => setOverlay(status)}
        content={contentBody}
        show={open}
        entryName={entryName}
        statusAlert={statusComponent}
      />
    </>
  );
};

export const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.lightBlue1;
      break;
    case 'Grey':
      bgColor = colors.lightGray2;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

export const getBodyComponent = (bodyData?: any): any | null => {
  if (!bodyData || bodyData.references?.includes(null)) {
    return null;
  }

  return (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {bodyData}
    </RichText>
  );
};

export const getTabData = (tabItemArray: any) => {
  const tabsData = tabItemArray?.map((itemObj: any, index: number): any => {
    const formatedData = (
      <RichText className={`RichText ${richTextStyles().styles}`}>
        {itemObj?.tabBody}
      </RichText>
    );

    let tabIcon = null;
    if (itemObj.tabTitleIcon?.file?.url) {
      tabIcon = (
        <img
          src={itemObj?.tabTitleIcon?.file?.url}
          style={{ width: '28px' }}
          alt={itemObj?.tabTitle}
        />
      );
    }
    return {
      label: itemObj.tabTitle,
      data: formatedData,
      icon: tabIcon,
    };
  });
  return tabsData;
};
export const getTabsetComponent = (tabsetData: any | null): any | null => {
  const formatedIntroCopy: any = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {tabsetData?.tabsetIntroCopy}
    </RichText>
  );
  const tabData = tabsetData.addItems ? getTabData(tabsetData.addItems) : null;

  return (
    tabData && (
      <Tabset
        header={tabsetData?.tabsetHeader}
        intro={formatedIntroCopy}
        tabsetImage={tabsetData?.tabsetImage}
        tabData={{
          tabs: tabData,
        }}
        imagePosition={tabsetData.imagePosition}
      />
    )
  );
};

export const getAccordionComponent = (
  accordionData: any | null,
): any | null => {
  return (
    <PGEAccordion
      accordionItems={accordionData?.accordionItems}
      accordionHeader={accordionData?.accordionHeader}
      accordionIntroCopy={accordionData?.accordionIntroCopy}
      colorBackground={getBackgroundColor(accordionData?.colorBackground)}
    />
  );
};

export const getContactComponent = (contactData: any | null): any | null => {
  const contactCopy = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {contactData?.contactCopy}
    </RichText>
  );

  const phoneNumber = (
    <a href={`tel:+1 ${contactData?.phoneNumber}`}>
      {contactData?.phoneNumber}
    </a>
  );
  const emailAddress = (
    <a href={`mailto:${contactData?.emailAddress}`}>
      {contactData?.emailAddress}
    </a>
  );

  return (
    <PgeContact
      contactCopy={contactCopy}
      contactName={contactData?.contactName}
      emailAddress={emailAddress}
      phoneNumber={phoneNumber}
    />
  );
};

export const getColumnComponent = (ColumnData: any | null): any | null => {
  const ColumnLength = ColumnData?.column?.length;
  const output = [];

  const shortDesc = ColumnData?.columnIntroCopy ? (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {ColumnData?.columnIntroCopy}
    </RichText>
  ) : undefined;

  for (let i = 0; i < ColumnLength; i++) {
    const callout = getComponent(ColumnData?.column[i], i);
    output.push(callout);
  }
  return (
    <Column
      header={ColumnData?.columnHeader}
      data={output}
      backGroundColor={getBackgroundColor(ColumnData?.colorBackground)}
      shortDesc={shortDesc}
    />
  );
};

export const getHeroComponent = (entries: any) => {
  const [hero] = entries?.filter(
    (entry: any) => entry?.__typename === 'ContentfulHeaderhero',
  );
  const btnProps = getButtonVariable(hero?.button, hero?.buttonLink);
  return (
    <>
      {hero && (
        <LandingHeroImage
          image={hero?.image?.file?.url}
          heading={hero?.heading}
          headingLineTwo={hero?.headingLineTwo}
          copy={hero?.subheading}
          button={btnProps}
          imagePosition={hero.imagePosition}
        />
      )}
    </>
  );
};

export const getButtonVariable = (btnObj: any, btnLink: any) => {
  let btnJSON = null;
  if (btnObj || btnLink) {
    let btnLinkUrl = null;
    let icon = null;
    let target = null;

    switch (btnLink?.__typename) {
      case 'ContentfulWrapperExternalLink': {
        if (btnLink && btnLink?.websiteUrl) {
          btnLinkUrl = btnLink?.websiteUrl;
          target = !(
            btnLink?.websiteUrl?.includes('mailto:') ||
            btnLink?.websiteUrl?.includes('tel:') ||
            (btnLink?.websiteUrl?.charAt(0) === '/' &&
              btnLink?.websiteUrl?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        } else {
          btnLinkUrl = btnLink?.slug;
          target = !(
            btnLink?.slug?.includes('mailto:') ||
            btnLink?.slug?.includes('tel:') ||
            (btnLink?.slug?.charAt(0) === '/' &&
              btnLink?.slug?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        }
        break;
      }
      case 'ContentfulWrapperMediaAsset': {
        if (typeof btnLink.media !== 'undefined') {
          const { contentType, url } = btnLink?.media?.file;
          icon = contentType ? getIcon(contentType) : null;
          btnLinkUrl = url;
          target = '_blank';
        }

        break;
      }
      default: {
        const { check, resolve } = usePathContext();

        if (check(btnLink?.slug)) {
          btnLinkUrl = resolve(btnLink?.slug);
        } else {
          btnLinkUrl =
            btnLink?.slug?.charAt(0) === '/'
              ? btnLink?.slug
              : `/${btnLink?.slug}`;
        }
      }
    }

    btnJSON =
      btnLink?.__typename === 'ContentfulPageOverlay'
        ? {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="PGE" className={useStyles().iconSize} />
            ) : null,
            buttonLink: btnLink,
          }
        : {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="PGE" className={useStyles().iconSize} />
            ) : null,
            onClick: btnLinkUrl,
            target: target,
          };
  }
  return btnJSON;
};

export const getCallOutComponent = (CalloutData: any | null): any | null => {
  const shortDesc = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {CalloutData?.shortDescription}
    </RichText>
  );
  const btnProps = getButtonVariable(
    CalloutData?.button,
    CalloutData?.buttonLink,
  );

  return (
    <Callout
      photo={CalloutData?.image?.file?.url}
      heading={CalloutData?.heading}
      shortDescription={shortDesc}
      button={btnProps}
      imagePosition={CalloutData.imagePosition}
    />
  );
};

export const getComponent = (itemObj: any | null, index: any): any | null => {
  const id = itemObj?.contentful_id;
  const classes = useStyles();
  const richText = richTextStyles();

  // Prepend an undescore to the id value to ensure that it doesn't start with a digit
  // https://www.w3.org/TR/CSS2/syndata.html
  // 4.1.3 Characters and case
  const anchorId = itemObj?.anchorLabel ? `_${id}` : undefined;

  switch (itemObj?.__typename) {
    case 'ContentfulContentBody': {
      return (
        <div
          id={anchorId}
          key={index}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
        >
          <RichText className={`RichText ${richText.styles}`}>
            {itemObj?.bodyCopy}
          </RichText>
        </div>
      );
    }
    case 'ContentfulElementJumpLinks': {
      return (
        <div
          key={index}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
        >
          <JumpLink {...itemObj} />
        </div>
      );
    }
    case 'ContentfulHeaderSplit': {
      if (index) {
        return (
          <div
            key={index}
            className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          >
            {getSplitHeaderComponent(itemObj)}
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulHeaderhero': {
      if (index) {
        return (
          <div
            key={index}
            className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          >
            {getHeroComponent([itemObj])}
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulElementPullquote': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getPullQuoteComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementCta': {
      if (itemObj.imageBorderColor) {
        return (
          <div id={anchorId} key={`${id}${index}`}>
            <CTACard {...(itemObj as CtaCardProps)} />
          </div>
        );
      }
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={`${id}${index}`}
        >
          {getCTAComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulWrapperForm': {
      return (
        <div
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getJotFormComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementMessagingBlock': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          <MessagingBlock {...itemObj} />
        </div>
      );
    }
    case 'ContentfulWrapperImage': {
      return <WrapperImage {...itemObj} key={itemObj.contentful_id + index} />;
    }
    case 'ContentfulElementContact': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.smallSpacing} ${itemObj.__typename}`}
          key={index}
        >
          {getContactComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementCallout': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getCallOutComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementColumns2': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getColumnComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementColumns3': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getColumnComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleGallery': {
      const { galleryItems } = itemObj;

      return (
        <GallerySlider id={anchorId} key={`${id}${index}`} {...itemObj}>
          {galleryItems}
        </GallerySlider>
      );
    }
    case 'ContentfulModuleRelated': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getRelatedComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleAccordion': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getAccordionComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleTabset': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getTabsetComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulWrapperembedMedia': {
      return (
        <div
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          <MediaWrapper {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModulePromoGroup': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ContentfulModulePromoGroup`}
          key={index}
        >
          {getPromoGroupComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulContentStatusAlert': {
      return (
        <div className={`spacingComponent ${itemObj.__typename}`} key={index}>
          {getStatusAlertComponent([itemObj])}
        </div>
      );
    }
    /* case 'ContentfulModulePromoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          {getPromoItemComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModulePromoVideoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          {getPromoItemComponent(itemObj)}
        </div>
      );
    }*/
    case 'ContentfulModuleStaticList': {
      return (
        <StaticList
          id={anchorId}
          key={`${id}${index}`}
          {...(itemObj as StaticListProps)}
        />
      );
    }
    /*case 'ContentfulUiTableExtention': {
      return <TableWrapper id={anchorId} key={id + index} {...itemObj} />;
    }
    */
    default: {
      return <React.Fragment key={index}></React.Fragment>; // ???
    }
  }
};

export const getSplitHeaderComponent = (
  splitHeaderData: any | null,
): any | null => {
  const description = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {splitHeaderData?.introContent}
    </RichText>
  );

  const btnProps = getButtonVariable(
    splitHeaderData?.button,
    splitHeaderData?.buttonLink,
  );

  return (
    <SplitHeader
      image={splitHeaderData?.image?.file?.url}
      video={splitHeaderData?.videowrappedMedia?.url}
      largeHeadline={splitHeaderData?.heading}
      paragraphHeadline={splitHeaderData?.subheading}
      description={description}
      button={btnProps}
      imagePosition={splitHeaderData.imagePosition}
    />
  );
};

export const getPullQuoteComponent = (
  pullQuoteData: any | null,
): any | null => {
  return (
    <PullQuote
      image={pullQuoteData?.image?.file?.url}
      statement={pullQuoteData?.textStatement?.textStatement}
      attribution={pullQuoteData?.attributionLine}
      bgColor={
        pullQuoteData.colorBackground
          ? getBackgroundColor(pullQuoteData?.colorBackground)
          : colors.lightBlue1
      }
    />
  );
};

export const getCTAComponent = (CTAData: any | null): any | null => {
  const btnProps = getButtonVariable(CTAData?.button, CTAData?.buttonLink);

  return (
    <CTA
      header={CTAData?.heading}
      description={CTAData?.shortDescription}
      bgMode={CTAData?.displayOptions}
      image={CTAData?.image?.file?.url}
      button={btnProps}
    />
  );
};

export const getIcon = (contentType: any) => {
  switch (contentType) {
    case 'application/pdf': {
      return '/static/icon_PDF.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return '/static/icon_word.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return '/static/icon_excel.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return '/static/icon_powerpoint.svg';
    }
    default: {
      return null;
    }
  }
};

export const chunk = (array: any, size: string) => {
  const numericSize: number = size ? parseInt(size) : 2;
  const chunked_arr = [];
  const copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / numericSize); // Round up to the nearest integer
  for (let i = 0; i < numericSize; i++) {
    chunked_arr.push(copied.splice(0, numOfChild));
  }
  return chunked_arr;
};
export const getJotFormComponent = (formData: any | null): any | null => {
  return <Jot src={formData.formUrl} />;
};
export const getRelatedComponent = (relatedData: any | null): any | null => {
  const formatedLegacy = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {relatedData?.legacySupplementalLinks}
    </RichText>
  );

  const formatedIntroCopy = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {relatedData?.relatedLinksIntroCopy}
    </RichText>
  );

  const chunkedArray = chunk(
    relatedData?.referenceLInks,
    relatedData?.distributeAcrossColumns,
  );
  return (
    <Related
      entryName={relatedData.entryName}
      relatedLinksHeader={relatedData.relatedLinksHeader}
      relatedLinksIntroCopy={formatedIntroCopy}
      headerIntroAlignment={
        relatedData.headerIntroAlignment !== null
          ? relatedData.headerIntroAlignment
          : true
      }
      referenceLinks={chunkedArray}
      distributeAcrossColumns={relatedData.distributeAcrossColumns}
      legacySupplementalLinks={formatedLegacy}
    />
  );
};

export const getStatusAlertComponent = (
  statusAlertData: any | null,
): any | null => {
  if (Array.isArray(statusAlertData)) {
    return statusAlertData.map((notification: any, index: number) => {
      const alertCopy = (
        <RichText className={`RichText ${richTextStyles().styles}`}>
          {notification?.alertCopy}
        </RichText>
      );

      return (
        <AlertNotifications
          key={index}
          message={alertCopy}
          title={notification?.alertHeading}
          severity={notification?.alertIconType?.toLowerCase()}
        />
      );
    });
  } else if (statusAlertData) {
    const alertCopy = (
      <RichText className={`RichText ${richTextStyles().styles}`}>
        {statusAlertData?.alertCopy}
      </RichText>
    );

    return (
      <AlertNotifications
        message={alertCopy}
        title={statusAlertData?.alertHeading}
        severity={statusAlertData?.alertIconType?.toLowerCase()}
      />
    );
  } else {
    return <></>;
  }
};

export const getPromoGroupComponent = (
  promoGroupData: any | null,
): any | null => {
  const promoIntroCopy = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {promoGroupData?.promoIntroCopy}
    </RichText>
  );

  const promoItems: any = [];
  promoGroupData?.addItems?.map((item: any) => {
    promoItems.push(getPromoItemComponentPropsObject(item));
  });

  return (
    <PromoGroup
      promoItems={promoItems}
      header={promoGroupData?.promoHeader}
      desc={promoIntroCopy}
      bgColor={getBackgroundColor(promoGroupData?.colorBackground)}
    />
  );
};

export const getPromoItemComponentPropsObject = (
  promoItemData: any | null,
): any | null => {
  let mediaType;
  let promoLink;
  let promoHeaderLink = null;

  const description = (
    <RichText className={`RichText ${richTextStyles().styles}`}>
      {promoItemData?.description}
    </RichText>
  );

  if (promoItemData?.image?.file?.url) {
    mediaType = 'image';
  } else if (promoItemData?.videowrappedImage?.url) {
    mediaType = 'video';
  } else {
    mediaType = '';
  }

  if (mediaType === 'image') {
    promoLink = promoItemData?.promoLInk;
  } else if (mediaType === 'video') {
    promoLink = promoItemData?.promoLink;
  } else {
    promoLink = null;
  }

  switch (promoLink?.__typename) {
    case 'ContentfulWrapperMediaAsset': {
      const { file } = promoLink?.media;
      promoHeaderLink = file ? file?.url : null;
      break;
    }
    case 'ContentfulPageOverlay': {
      promoHeaderLink = promoLink;
      break;
    }
    default: {
      promoHeaderLink = promoLink ? promoLink?.slug : null;
    }
  }
  const mediaURL =
    mediaType === 'image'
      ? promoItemData?.image?.file?.url
      : mediaType === 'video'
      ? promoItemData?.videowrappedImage?.url
      : '';

  return {
    header: promoItemData?.heading,
    description: description,
    mediaType: mediaType,
    mediaURL: mediaURL,
    link: promoHeaderLink,
    imageLink: promoItemData?.imageLink,
    imagePosition: promoItemData?.imagePosition
  };
};

/*export const getPromoItemComponent = (
  promoItemData: any | null,
): any | null => {
  const promoItem = getPromoItemComponentPropsObject(promoItemData);

  return (
    <PromoItem
      header={promoItem?.heading}
      description={promoItem?.description}
      mediaType={promoItem?.mediaType}
      mediaURL={promoItem?.mediaURL}
      link={promoItem?.link}
      imageLink={promoItem?.imageLink}
    />
  );
}; */

export const getEntriesComponent = (graphqlObj: any): any | null => {
  const result = [];
  for (let i = 0; i <= graphqlObj.length; i++) {
    result.push(getComponent(graphqlObj[i], i));
  }
  const entriesComponent = <React.Fragment>{result}</React.Fragment>;
  return entriesComponent;
};

export const renderPageComponents = (props: any) => {
  const entriesData = props ? props.entriesData : null;

  if (entriesData) {
    const entriesComponent = getEntriesComponent(entriesData);
    return <>{entriesComponent}</>;
  } else {
    return null;
  }
};
