import React, { useState, ReactNode } from 'react';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
        borderRadius: 0,
      },
    },
    action: {
      alignItems: 'start',
    },
    bodyContainer: {
      '& :first-child': {
        marginTop: 0,
      },
    },
  }),
);

const BroadcastCommunicationBanner = ({
  severity = 'info',
  messageTitle,
  messageBody,
  ...props
}: AlertProps & { messageTitle: ReactNode; messageBody?: ReactNode }) => {
  const classes = useStyles();
  const { bodyContainer, ...rest } = classes;
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
      <Alert
        data-testid="broadcast-message-alert"
        classes={rest}
        severity={severity}
        variant="filled"
        {...props}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            data-testid="broadcast-message-close-action"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        }
      >
        {/* <AlertTitle data-testid="broadcast-message-title">
          {messageTitle}
        </AlertTitle> */}
        <div className={bodyContainer}>{messageBody}</div>
      </Alert>
    </Collapse>
  );
};

export default BroadcastCommunicationBanner;
