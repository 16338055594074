import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PgeButton from '../../pge-button/_PgeButton';
import RichText, { richTextStyles } from '../../rich-text';
//import useAttrs from '../../../hooks/useAttrs';
//import { CtaCardProps as Props, Omits } from './types.d';
import useStyles from './CtaCard.styles';

function CtaCard({
  heading,
  button,
  buttonLink,
  image,
  mobileImage,
  shortDescription,
  imageBorderColor,
  ...props
}: any): ElementNode {
  // Default props
  const _border = imageBorderColor || 'DarkBlue';

  // Vars
  const mobileFile = mobileImage?.file;
  const imageHeight = mobileFile?.details?.image?.height ?? 1200;
  const imageWidth = mobileFile?.details?.image?.width ?? 360;
  const imageRatio = Math.floor((imageHeight / imageWidth) * 100);
  const richText = richTextStyles();
  //const { className = '', ...attrs } = useAttrs<any>(props, Omits);
  const $img = useRef<HTMLDivElement>(null);
  const $btn = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({ imageRatio });

  useEffect(() => {
    if (expanded) {
      $img.current?.classList.add('isExpanded');
      $btn.current?.setAttribute('disabled', '');
    }
  }, [expanded]);

  return (
    <aside
      className={`CtaCard bkg-${_border} ${classes.root}`}
      aria-label={heading || undefined}
      /* {...attrs} */
    >
      {heading && (
        <Typography variant="h1" className={classes.heading}>
          {heading}
        </Typography>
      )}
      {image?.file?.url && (
        <div className={`${classes.image} ${classes.expand}`} ref={$img}>
          <picture>
            {mobileFile && (
              <>
                <source media="(max-width:767px)" srcSet={mobileFile.url} />
                <source media="(min-width:768px)" srcSet={image.file.url} />
              </>
            )}
            <img src={image.file.url} alt="" />
          </picture>
          <PgeButton
            className={classes.showMore}
            theme="Pill"
            startIcon="Plus"
            onClick={() => setExpanded(true)}
            ref={$btn}
          >
            Show more
          </PgeButton>
        </div>
      )}
      <RichText className={`RichText ${richText.styles} bkg-Dark`}>
        {shortDescription}
      </RichText>
      <PgeButton className={classes.cta} link={buttonLink} theme="Outline">
        {button?.buttonText}
      </PgeButton>
    </aside>
  );
}

export default CtaCard;
