import { createElement, Fragment, ExoticComponent } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import block from './block';
import inline from './inline';
import { RichTextProps as Props, Wrap } from './types.d';

type Element = Exclude<Wrap, 'fragment' | 'none'> | ExoticComponent;

function RichText({ children, wrap, override, ...props }: Props): ElementNode {
  // Default props
  const _wrap = wrap || 'fragment';
  const _override = override || {};

  if (!children) {
    return null;
  }

  // Vars
  const baseWrap = _wrap === 'fragment' && !!props.className ? 'div' : _wrap;
  const element: Element =
    baseWrap === 'fragment' || baseWrap === 'none' ? Fragment : baseWrap;
  const options: Options = {
    renderNode: {
      ...block(_wrap !== 'none'),
      ...inline(),
    },
    ..._override,
  };

  return createElement(
    element,
    { ...props },
    renderRichText(children, options),
  );
}

export default RichText;
