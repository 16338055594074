import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import Container from '@material-ui/core/Container';
import PgeButton from '../pge-button/_PgeButton';
import { PgeButtonProps } from '../pge-button/types.d';
import ImageLink from '../image-link';
import RichText, { richTextStyles } from '../rich-text';
import { ColorName } from '../../constants';
import { MessagingProps as Props } from './types.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: theme.spacing(5),
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5, 0),
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
      },
    },
    mediaDiv: {
      width: '50%',
      float: 'left',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(2.5),
        padding: 0,
      },
      '& > iframe': {
        width: '100%',
        height: 275,
        borderRadius: 5,
      },
    },
    alignLeft: {
      flexDirection: 'row',
      '& .media': {
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
          paddingRight: 0,
        },
      },
    },
    alignRight: {
      flexDirection: 'row-reverse',
      '& .media': {
        paddingLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
        },
      },
    },
    image: {
      width: '100%',
      borderRadius: theme.spacing(0.625),
      boxShadow: '0px 3px 6px #00002930',
      objectFit: 'cover',
      [theme.breakpoints.up('sm')]: {
        height: 275,
        objectPosition: ({ imagePosition }: Props) => imagePosition,
      },
    },
    details: {
      width: '50%',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mainHeading: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      color: colors.noirBlur,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    paragraphHeadline: {
      fontWeight: 100,
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(36),
      color: colors.noirBlur,
      margin: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
      },
      '& > a': {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    description: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(24),
      color: colors.noirBlur,
      margin: theme.spacing(0),
      fontWeight: theme.spacing(50),
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
  }),
);

const MessageBlock: FC<Props> = ({
  largeHeadline,
  paragraphHeadline,
  paragraphCopy,
  image,
  video,
  imageLink,
  button,
  buttonLink,
  colorBackground,
  imageAlignment,
  imagePosition,
}) => {
  const richText = richTextStyles();
  const classes = useStyles({ imagePosition });

  let direction = '';

  if (
    (imageAlignment === 'Left' && image) ||
    (imageAlignment === 'Left' && video)
  ) {
    direction = `${classes.root} ${classes.alignLeft}`;
  }
  if (
    (imageAlignment === 'Right' && image) ||
    (imageAlignment === 'Right' && video)
  ) {
    direction = `${classes.root} ${classes.alignRight}`;
  }

  return (
    <section
      className={classes.main}
      style={
        colorBackground
          ? { backgroundColor: ColorName[colorBackground] }
          : { backgroundColor: '#fff' }
      }
    >
      <Container>
        {largeHeadline && (
          <h1 className={classes.mainHeading}>{largeHeadline}</h1>
        )}
        <div className={direction}>
          <div className={(image || video) && `media ${classes.mediaDiv}`}>
            {image?.file?.url && (
              <ImageLink link={imageLink} text={paragraphHeadline}>
                <img className={classes.image} src={image.file.url} alt="" />
              </ImageLink>
            )}
            {video && <PgeVideoPlayer video={video} />}
          </div>
          <div className={classes.details}>
            {paragraphHeadline && (
              <h3 className={classes.paragraphHeadline}>
                <PgeButton link={buttonLink} theme="Text" display="Inline">
                  {paragraphHeadline}
                </PgeButton>
              </h3>
            )}
            {paragraphCopy && (
              <RichText className={`${richText.styles} ${classes.description}`}>
                {paragraphCopy}
              </RichText>
            )}
            {button && (
              <PgeButton
                data-testid="button"
                link={buttonLink}
                theme={button?.buttonType as PgeButtonProps['theme']}
              >
                {button?.buttonText}
              </PgeButton>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MessageBlock;
