import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import PgeButton from '../pge-button/_PgeButton';
import ImageLink from '../image-link';
import { Field } from '../../contentful.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootHorizontal: {
      display: 'flex',
      backgroundColor: colors.white,
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: theme.spacing(0.625),
      alignItems: 'center',
      minHeight: 275,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    rootVertical: {
      display: 'block',
      backgroundColor: colors.white,
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: theme.spacing(0.625),
      minHeight: 550,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minHeight: 270,
      },
    },
    detailsContainerHorizontal: {
      padding: theme.spacing(3.75, 5),
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(2.5, 2.5, 3.75, 2.5),
      },
    },
    detailsContainerVertical: {
      padding: theme.spacing(3.75, 3.75, 3.125, 3.75),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5, 2.5, 1.875, 2.5),
      },
    },
    header: {
      fontSize: theme.spacing(3),
      fontWeight: theme.spacing(12.5),
      lineHeight: theme.typography.pxToRem(28),

      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    link: {
      color: colors.sparkBlue,
    },
    woLink: {
      color: colors.noirBlur,
    },
    description: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
      },
      wordBreak: 'break-word',
    },
    mediaContainerHorizontal: {
      lineHeight: theme.spacing(0),
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mediaContainerVertical: {
      lineHeight: theme.spacing(0),
    },
    imageHorizontal: {
      width: '100%',
      borderRadius: theme.spacing(0.625, 0, 0, 0.625),
      height: 275,
      objectFit: 'cover',
      objectPosition: ({ imagePosition }: Props) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 135,
      },
    },
    imageVertical: {
      width: '100%',
      borderRadius: theme.spacing(0.625, 0.625, 0, 0),
      height: 275,
      objectFit: 'cover',
      objectPosition: ({ imagePosition }: Props) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 135,
      },
    },
    videoFrame: {
      '& > iframe': {
        width: '100%',
        height: 275,
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
          height: 135,
        },
      },
    },
  }),
);

interface Props {
  layoutType?: string;
  mediaURL?: string;
  mediaType?: string;
  header?: string;
  description?: React.ReactElement;
  link?: string;
  readonly imageLink?: GatsbyTypes.ModulePromoItemFragment['imageLink'];
  readonly imagePosition: Field['Position'];
}

const PromoItem: FC<Props> = props => {
  const classes = useStyles(props);
  const {
    layoutType = 'vertical',
    header = '',
    description = '',
    mediaURL = '',
    mediaType = '',
    link = '',
    imageLink,
  } = props;

  const headerClass = link
    ? `${classes.header} ${classes.link}`
    : `${classes.header} ${classes.woLink}`;

  const imageClass =
    layoutType === 'vertical' ? classes.imageVertical : classes.imageHorizontal;

  return (
    <div
      className={
        layoutType === 'vertical'
          ? classes.rootVertical
          : classes.rootHorizontal
      }
    >
      {mediaURL && (
        <div
          className={
            layoutType === 'vertical'
              ? classes.mediaContainerVertical
              : classes.mediaContainerHorizontal
          }
        >
          {mediaType === 'image' && (
            <ImageLink link={imageLink} text={header}>
              <img className={imageClass} src={mediaURL} alt="" />
            </ImageLink>
          )}
          {mediaType === 'video' && (
            <div className={classes.videoFrame}>
              <PgeVideoPlayer video={{ url: `${mediaURL}` }} />
            </div>
          )}
        </div>
      )}
      <div
        className={
          layoutType === 'vertical'
            ? classes.detailsContainerVertical
            : classes.detailsContainerHorizontal
        }
      >
        {header && (
          <h3 className={headerClass}>
            <PgeButton link={link} theme="Text" display="Inline">
              {header}
            </PgeButton>
          </h3>
        )}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
      </div>
    </div>
  );
};

export default PromoItem;
