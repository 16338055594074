// Object to match ContentfulFields
export const ColorName = Object.freeze({
  Grey: '#F8F8F8',
  DarkGrey: '#686C71',
  DarkBlue: '#20415B',
  LightBlue: '#0371B4',
  Blue: '#E7F9FB',
  None: '#FFF',
  RiverBlue: '#E1F8FA',
});

export type Color = {
  Name: keyof typeof ColorName;
};
