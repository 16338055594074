import React, { FC, useMemo } from 'react';
import { getLayout } from './PromoGroup.utils';
import { Grid } from '@material-ui/core/';
import colors from '../../themes/main-colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PromoItem from '../promo-item/';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: Props) => ({
      backgroundColor: props.bgColor,
      padding: theme.spacing(5),
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5, 0),
      },
      '& .MuiGrid-spacing-xs-3': {
        width: '100%',
        margin: 0,
        padding: theme.spacing(0),
        '& .MuiGrid-grid-md-6': {
          display: 'flex',
          padding: theme.spacing(0, 0, 2.5, 0),
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.25, 0),
          },
        },
        '& .MuiGrid-grid-md-6:first-child': {
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.25, 0),
          },
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2.5, 2.5, 0),
          },
        },
        '& .MuiGrid-grid-md-12': {
          padding: theme.spacing(0),
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.25, 0),
          },
        },
        '& .MuiGrid-grid-md-4:first-child': {
          padding: theme.spacing(1.25, 1.25, 1.25, 0),
          [theme.breakpoints.down('sm')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
        '& .MuiGrid-grid-md-4': {
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
        '& .MuiGrid-grid-md-4:last-child': {
          padding: theme.spacing(1.25, 0, 1.25, 1.25),
          [theme.breakpoints.down('sm')]: {
            padding: `${theme.typography.pxToRem(12)} 0`,
          },
        },
      },
    }),
    header: {
      marginBottom: theme.spacing(3),
      fontSize: theme.spacing(4.5),
      fontWeight: 400,
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1.5),
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    description: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      margin: theme.spacing(2.5, 0),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        fontSize: theme.spacing(2),
      },
    },
    linking: {
      '& > a': {
        textDecoration: 'none',
      },
    },
  }),
);

type PromoItem = {
  layoutType?: string;
  mediaURL?: string;
  mediaType?: string;
  header?: string;
  description?: React.ReactElement;
  readonly imageLink?: GatsbyTypes.ModulePromoItemFragment['imageLink'];
};

interface Props {
  promoItems: PromoItem[];
  leftNav?: boolean;
  header?: string;
  desc?: React.ReactElement;
  bgColor?: any;
}

const PromoGroup: FC<Props> = (props) => {
  const classes = useStyles(props);
  const perColumn = props.leftNav ? 2 : 3;
  const promoArrayList: any = useMemo(
    () => getLayout(props.promoItems, perColumn),
    [props.promoItems, perColumn],
  );

  const generateColumn = (item: any, index: number, columnWidth: number) => {
    let mdValue: any = 6;
    if (props.leftNav) {
      mdValue = columnWidth === 1 ? 12 : 6;
    } else {
      if (columnWidth === 2) {
        mdValue = 6;
      } else {
        mdValue = columnWidth === 1 ? 12 : 4;
      }
    }

    return (
      <Grid item xs={12} md={mdValue} key={index}>
        <PromoItem
          layoutType={mdValue === 12 ? 'horizontal' : 'vertical'}
          mediaURL={item?.mediaURL}
          mediaType={item?.mediaType}
          header={item?.header}
          description={item?.description}
          link={item?.link}
          imageLink={item?.imageLink}
          imagePosition={item?.imagePosition}
        />
      </Grid>
    );
  };

  const generateRow = (rowArray: any, length: number) => {
    const rowObject = rowArray.map((rowData: any, index: number) => {
      return generateColumn(rowData, index, length);
    });
    return rowObject;
  };

  return (
    <div className={classes.root}>
      <Container>
        {props.header && <div className={classes.header}>{props.header}</div>}
        {props.desc && <div className={classes.description}>{props.desc}</div>}
        <Grid container spacing={3} justifyContent="flex-start">
          {promoArrayList.map((rowArray: any) => {
            return generateRow(rowArray, rowArray.length);
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default PromoGroup;
