import {
  ComponentPropsWithoutRef,
  ComponentProps,
  Dispatch,
  Reducer as ReactReducer,
} from 'react';
import { Animate } from '../../../constants';
import { Field } from '../../../contentful';

export const Omits = ['aria-label', 'style', 'galleryItems'] as const;

export type State = {
  position: number;
  forward: boolean;
  prev: number;
  next: number;
  total: number;
};

export interface Action {
  type: Animate;
}

export type UseSliderContext = {
  readonly context: State;
  readonly dispatch: Dispatch<Action>;
};

export type Reducer = ReactReducer<State, Action>;

interface GalleryBase
  extends GatsbyTypes.GallerySliderFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly children: ReadonlyArray<GatsbyTypes.SlideFragment>;
  readonly total: number;
  readonly colorBackground: Field['Background'];
}

interface SlideBase extends GatsbyTypes.SlideFragment, ComponentPropsWithoutRef<'figure'> {
  readonly num: number;
  readonly total: number;
}

interface NavBtnBase extends ComponentPropsWithoutRef<'button'> {
  readonly use: 'Prev' | 'Next';
}

export type GalleryProps = Partial<Omit<GalleryBase, typeof Omits[number]>>;
export type SlideProps = Partial<SlideBase>;
export type NavBtnProps = Omit<NavBtnBase, 'type'>;
