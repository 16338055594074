/* tslint:disable:no-implicit-dependencies */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Properties } from 'csstype';
import { Shadow } from '../../../constants';

const img: Properties = {
  display: 'block',
  width: '100%',
  maxWidth: 'max-content',
  margin: 'auto',
  fontSize: 0,
};

const useStyles = makeStyles(
  ({ palette, breakpoints, spacing, typography }: Theme) => {
    // Colors
    const text = palette.text.primary;
    const lightGrey = palette.grey[100];
    const grey = palette.grey[300];

    return createStyles({
      root: {
        marginTop: spacing(4),
        [breakpoints.up('sm')]: { marginTop: spacing(8) },

        '& img': { ...img },
      },

      header: {
        [breakpoints.up('sm')]: {
          display: 'grid',
          gridTemplateRows: 'auto',
          columnGap: spacing(3),

          '&.align-Center': {
            textAlign: 'center',
          },

          '&.align-Left, &.align-Right': {
            gridTemplateColumns: '1fr 1fr',
          },
        },
      },

      heading: {
        margin: 0,
        color: text,
        fontSize: spacing(3),
        lineHeight: typography.pxToRem(28),
        fontWeight: 100,

        [breakpoints.up('sm')]: {
          gridColumn: 'span 1',
          alignSelf: 'end',
          fontSize: spacing(4.5),
          lineHeight: typography.pxToRem(40),

          '.align-Center &': {
            textAlign: 'center',
          },
        },
      },

      introCopy: {
        marginTop: spacing(1.5),

        [breakpoints.up('sm')]: {
          display: 'flex',
          flexFlow: 'column wrap',
          alignItems: 'center',
          gridColumn: 'span 1',
        },

        '& > *': {
          maxWidth: 900,
        },
      },

      imageContainer: {
        display: 'flex',
        marginTop: spacing(1.5),

        [breakpoints.up('sm')]: {
          '.align-Center &': {
            marginTop: spacing(2),
          },

          '.align-Left &, .align-Right &': {
            gridRow: '1 / 3',
            margin: 0,
          },

          '.align-Left &': {
            gridColumn: '1 / 2',
          },

          '.align-Right &': {
            gridColumn: '2 / 3',
          },
        },

        '& img': {
          borderRadius: spacing(0.875),
          boxShadow: Shadow.ONE,
        },
      },

      overlayImage: {
        width: '100%',
        marginTop: spacing(1.5),

        '& img': {
          ...img,
          borderRadius: spacing(0.875),
          boxShadow: Shadow.ONE,
        },
      },

      caption: {
        marginTop: spacing(2),
        color: text,
        fontSize: spacing(2),
        textAlign: 'center',

        [breakpoints.up('sm')]: {
          display: 'flex',
          flexFlow: 'column wrap',
          alignItems: 'center',
          textAlign: 'center',
        },

        '& p': {
          marginTop: spacing(2),
          maxWidth: 900,
        },

        '& img': {
          maxWidth: 600,
          margin: spacing(2, 'auto', 0),
        },
      },

      buttonContainer: {
        display: 'flex',
        padding: 0,
        margin: spacing(3, 0, 0),
        position: 'relative',
        justifyContent: 'center',
      },

      button: {
        minWidth: '9ch',
        padding: spacing(1, 3),
        boxSizing: 'border-box',
        fontSize: spacing(2),
        borderRight: '1px solid currentColor',
        borderRadius: 0,

        '&:last-child': {
          borderRight: 'none',
        },

        '&.expanded': {
          background: lightGrey,
          borderRadius: spacing(0.625, 0.625, 0, 0),
          border: `1px solid ${grey}`,
          borderBottom: 'none',

          '&:hover': {
            background: lightGrey,
          },
        },

        [breakpoints.down('sm')]: {
          padding: spacing(1, 0),

          '& .MuiButton-label': {
            flexDirection: 'column-reverse',
          },

          '& .MuiButton-endIcon': {
            marginLeft: spacing(0),
          },
        },
      },

      expandContainer: {
        padding: spacing(0),
      },

      longDescriptionContainer: {
        background: lightGrey,
        border: `1px solid ${grey}`,
        borderRadius: spacing(0.625),
        marginTop: spacing(-0.25),
      },

      longDescription: {
        padding: spacing(2),
        textAlign: 'left',

        '& img': {
          marginTop: spacing(2),
        },
      },
    });
  },
);

export default useStyles;
