import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import Container from '@material-ui/core/Container';
import { Field } from '../../contentful.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.lightBlue1,
      display: 'flex',
      alignItems: 'center',
      minHeight: 320,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
        '& .MuiContainer-root': {
          padding: 0,
        },
      },
    },
    fixWidthContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    mediaDiv: {
      float: 'right',
      padding: theme.spacing(0, 0, 0, 7.5),
      lineHeight: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(2.5),
        padding: 0,
      },
      '& > iframe': {
        width: 462,
        height: 240,
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
          height: 175,
          width: '100%',
        },
      },
    },
    image: {
      width: 462,
      borderRadius: theme.spacing(0.625),
      height: 240,
      objectFit: 'cover',
      objectPosition: ({ imagePosition }: SplitHeaderProps) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 175,
        width: '100%',
      },
    },
    detail: {
      flex: 'auto',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5),
      },
    },
    largeHeadline: {
      fontSize: theme.spacing(4.5),
      fontWeight: theme.spacing(12.5),
      lineHeight: theme.typography.pxToRem(40),
      color: colors.noirBlur,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: theme.spacing(1.25),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    paragraphHeadline: {
      fontWeight: 'bold',
      fontSize: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(27),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    description: {
      fontSize: theme.spacing(2),
      lineHeight: theme.typography.pxToRem(20),
      color: colors.noirBlur,
      margin: theme.spacing(0),
    },
  }),
);

interface SplitHeaderProps {
  image?: string;
  video?: string;
  largeHeadline?: string;
  paragraphHeadline?: string;
  description?: any;
  button?: IPGEButtonProps | null;
  readonly imagePosition: Field['Position'];
}

export default (props: SplitHeaderProps) => {
  const classes = useStyles(props);

  const {
    image = '',
    video = '',
    largeHeadline = '',
    paragraphHeadline = '',
    description = '',
    button,
  } = {
    ...props,
  };

  return (
    <>
      <div className={classes.root}>
        <Container>
          <div className={classes.fixWidthContainer}>
            <div className={(image || video) && classes.mediaDiv}>
              {image && <img className={classes.image} src={image} />}
              {video && <PgeVideoPlayer video={{ url: `${video}` }} />}
            </div>
            <div className={classes.detail}>
              {largeHeadline && (
                <h1 className={classes.largeHeadline}>{largeHeadline}</h1>
              )}
              {paragraphHeadline && (
                <h3 className={classes.paragraphHeadline}>
                  {paragraphHeadline}
                </h3>
              )}
              {description && (
                <h3 className={classes.description}>{description}</h3>
              )}
              {button && <PgeButton {...button} />}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
