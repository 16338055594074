import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import SlugLink from '../slug-link';
import { Field } from '../../contentful.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 5,
      '& .MuiPaper-elevation1': {
        boxShadow: 'none ',
      },
      '& .MuiTypography-gutterBottom': {
        fontWeight: 'normal',
        color: colors.noirBlur,
        fontSize: theme.spacing(4.5),
        lineHeight: theme.typography.pxToRem(40),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          lineHeight: theme.typography.pxToRem(28),
        },
      },
      '& .MuiTypography-colorTextSecondary': {
        fontSize: theme.spacing(2.5),
        color: colors.noirBlur,
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(0, 3.75, 0, 1.25),
      },
      '& .MuiPaper-root': {
        backgroundColor: 'inherit',
      },
      '& .MuiCardActionArea-focusHighlight': {
        backgroundColor: 'inherit',
      },
      '& .MuiButtonBase-root': {
        cursor: 'auto',
      },
    },
    media: {
      height: 70,
      width: 70,
      margin: theme.spacing(0, 1.25, 2.5, 1.25),
    },
    imgMedia: {
      height: 275,
      width: '100%',
      borderRadius: theme.spacing(0.625, 0.625, 0, 0),
      margin: theme.spacing(1.25),
      backgroundPosition: ({ imagePosition }: Props) => imagePosition,
    },
    textColor: {
      '& > a': {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    a: {
      underLine: 'none',
    },
    button: {
      padding: theme.spacing(1.25, 0, 0, 2.5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2.5, 0),
      },
    },
  }),
);
interface Props {
  heading?: string;
  shortDescription?: any;
  photo?: any;
  button?: IPGEButtonProps | null;
  readonly imagePosition: Field['Position'];
}

const CallOut = (props: Props) => {
  const classes = useStyles(props);

  const getHeading = (
    heading: any,
    buttonProps: IPGEButtonProps | null | undefined,
  ) => {
    if (
      buttonProps &&
      (buttonProps.buttonMode === null || buttonProps.buttonMode === '')
    ) {
      return <SlugLink slug={buttonProps?.text} linkText={heading} />;
    } else {
      return heading;
    }
  };
  return (
    <div className={classes.root}>
      <Card>
        {props.photo && props.photo.includes('.svg') === true ? (
          <CardMedia className={classes.media} image={props.photo} />
        ) : (
          props.photo &&
          props.photo !== 'null' && (
            <CardMedia className={classes.imgMedia} image={props.photo} />
          )
        )}

        <CardContent>
          {props.heading && (
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.textColor}
            >
              {getHeading(props.heading, props.button)}
            </Typography>
          )}

          {props.shortDescription && <div>{props.shortDescription}</div>}
        </CardContent>

        {props.button?.buttonMode && (
          <div className={classes.button}>
            <PgeButton {...props.button} />
          </div>
        )}
      </Card>
    </div>
  );
};
export default CallOut;
